<template>
    <b-tabs>
        <b-tab title="Liste des utilisateurs">
            <users-table :users="users" :rights="rights" :brands="brands" :services="services" @on-refresh="fetchUsers"></users-table>
        </b-tab>
        <b-tab title="Liste des marques">
            <brands-table :brands="brands" @on-refresh="fetchBrands"></brands-table>
        </b-tab>
        <b-tab title="Liste des services">
            <services-table :services="services" @on-refresh="fetchServices"></services-table>
        </b-tab>
        <b-tab title="Liste des droits">
            <rights-table :rights="rights" :services="services" @on-refresh="fetchRights"></rights-table>
        </b-tab>
    </b-tabs>
</template>

<script>
    import UsersTable from './Users/index.vue';
    import BrandsTable from './brands-table.vue';
    import ServicesTable from './services-table.vue';
    import RightsTable from './rights-table.vue';
    import { mapActions } from 'vuex';
    export default {
        components: {
            UsersTable,
            BrandsTable,
            ServicesTable,
            RightsTable,
        },
        data() {
            return {
                users: [],
                brands: [],
                services: [],
                rights: [],
            };
        },
        mounted() {
            this.init();
        },
        methods: {
            ...mapActions({
                getUsers: 'app/getUsers',
                getBrands: 'app/getBrands',
                getServices: 'app/getServices',
                getRights: 'app/getRights',
            }),
            init() {
                this.$root.toggleGlobalLoading(true);
                let promises = [this.fetchUsers(), this.fetchBrands(), this.fetchServices(), this.fetchRights()];

                Promise.all(promises)
                    .then((res) => {})
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la récupérations des données'));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },

            fetchUsers() {
                return this.getUsers().then((res) => {
                    this.$set(this, 'users', res.data);
                });
            },

            fetchBrands() {
                return this.getBrands().then((res) => {
                    this.$set(this, 'brands', res.data);
                });
            },

            fetchRights() {
                return this.getRights().then((res) => {
                    this.$set(this, 'rights', res.data);
                });
            },

            fetchServices() {
                return this.getServices().then((res) => {
                    this.$set(this, 'services', res.data);
                });
            },
        },
    };
</script>

<style></style>
