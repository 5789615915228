<template>
    <generic-confirm-modal ref="modal" :title="`Lier un droit à ${user.email}`" @on-accept="linkRight" @on-cancel="closeModalLinkRight">
        <b-overlay :show="loading">
            <b-form @submit.stop.prevent>
                <b-form-group label="Marque" label-for="input-link-right-brand">
                    <b-form-select
                        id="input-link-right-brand"
                        v-model="newRight.brand"
                        :options="sortedBrands"
                        text-field="name"
                        value-field="name"
                        :state="ruleBrand || !showFormError ? null : false"
                    ></b-form-select>
                    <b-form-invalid-feedback :state="ruleBrand || !showFormError ? null : false"> Une marque doit ếtre sélectionnée </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group label="Droit" label-for="input-link-right">
                    <b-form-select id="input-link-right" v-model="newRight.rightId" :options="sortedRightsForSelect" :state="ruleRight || !showFormError ? null : false"></b-form-select>
                    <b-form-invalid-feedback :state="ruleRight || !showFormError ? null : false"> Une droit doit ếtre sélectionnée </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group label="Value" label-for="input-link-right-value">
                    <b-input id="input-link-right-value" v-model="newRight.value" :state="ruleValue || !showFormError ? null : false" />
                    <b-form-invalid-feedback :state="ruleValue || !showFormError ? null : false"> Une valeur >= 0 doit être selectionné </b-form-invalid-feedback>
                </b-form-group>
            </b-form>
        </b-overlay>
    </generic-confirm-modal>
</template>

<script>
    export default {
        props: {
            user: {
                type: Object,
                required: true,
            },
            rights: {
                type: Array,
                required: true,
            },
            brands: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                showFormError: false,
                newRight: {
                    brand: '',
                    rightId: '',
                    value: '',
                },
            };
        },
        computed: {
            rightsForSelect() {
                return this.rights.map((right) => {
                    return {
                        text: `${right.service}/${right.key}`,
                        value: right.rightId,
                    };
                });
            },

            sortedRightsForSelect() {
                return this.rightsForSelect.slice().sort((a, b) => {
                    return a.text.localeCompare(b.text);
                });
            },

            ruleBrand() {
                return this.newRight.brand !== '';
            },
            ruleRight() {
                return this.newRight.rightId !== '';
            },
            ruleValue() {
                let value = parseInt(this.newRight.value);
                return !isNaN(value) && value >= 0;
            },
            formValid() {
                return [this.ruleBrand, this.ruleRight, this.ruleValue].every((val) => val === true);
            },

            sortedBrands() {
                return this.brands.slice().sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
            },
        },
        methods: {
            open() {
                this.$refs.modal.open();
            },
            close() {
                this.$refs.modal.close();
            },
            linkRight() {
                if (!this.formValid) return (this.showFormError = true);
                let service = this.rights.find((right) => right.rightId === this.newRight.rightId)?.service;
                if (service === undefined) {
                    console.error("Right service can' be found");
                    this.$toast(this.$store.getters['app/errorToast']('Erreur lors de laison du droit'));
                    return;
                }
                this.$emit('on-link-right', { ...this.newRight, service });
            },
            closeModalLinkRight() {
                this.newRight.brand = '';
                this.newRight.rightId = '';
                this.newRight.value = '';
                this.showFormError = false;
            },
        },
    };
</script>

<style></style>
