<template>
    <generic-confirm-modal ref="modal" :title="`Lier une marque à ${user.email}`" @on-accept="linkBrand" @on-cancel="closeModal">
        <b-overlay :show="loading">
            <b-form @submit.stop.prevent>
                <b-form-group label="Marque" label-for="input-link-right-brand">
                    <b-form-select
                        id="input-link-right-brand"
                        v-model="brand"
                        :options="sortedBrands"
                        text-field="name"
                        value-field="name"
                        :state="ruleBrand || !showFormError ? null : false"
                    ></b-form-select>
                    <b-form-invalid-feedback :state="ruleBrand || !showFormError ? null : false"> Une marque doit ếtre sélectionnée </b-form-invalid-feedback>
                </b-form-group>
            </b-form>
        </b-overlay>
    </generic-confirm-modal>
</template>

<script>
    export default {
        props: {
            user: {
                type: Object,
                required: true,
            },
            brands: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                showFormError: false,
                brand: '',
            };
        },
        computed: {
            ruleBrand() {
                return this.brand !== '';
            },

            sortedBrands() {
                return this.brands.slice().sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
            },
        },
        methods: {
            open() {
                this.$refs.modal.open();
            },
            close() {
                this.$refs.modal.close();
            },
            linkBrand() {
                if (!this.ruleBrand) return (this.showFormError = true);
                this.$emit('on-link-brand', this.brand);
            },
            closeModal() {
                this.brand = '';
                this.showFormError = false;
            },
        },
    };
</script>

<style></style>
