<template>
    <b-card>
        <b-overlay :show="show" rounded="sm">
            <b-table ref="table" striped show-empty responsive :items="tableData" :fields="fields">
                <template #cell(value)="data">
                    <right-edit :user-right="data.item" @on-update-right="updateRights" @on-delete-right="deleteRight"></right-edit>
                </template>
            </b-table>
            <div class="d-flex justify-content-center mt-2">
                <b-btn variant="primary mr-3" @click="openModal('link-right-modal')">Lier un droit</b-btn>
                <b-btn variant="primary" @click="openModal('link-brand-modal')">Lier une marque</b-btn>
                <b-btn variant="primary ml-3" @click="openModal('link-service-modal')">Lier un service</b-btn>
            </div>
        </b-overlay>

        <link-right-modal ref="link-right-modal" :user="user" :rights="rights" :brands="brands" @on-link-right="linkRight"></link-right-modal>

        <link-brand-modal ref="link-brand-modal" :user="user" :brands="brands" @on-link-brand="linkBrand"></link-brand-modal>

        <link-service-modal ref="link-service-modal" :user="user" :brands="brands" :services="services" @on-link-brand="linkService"></link-service-modal>
    </b-card>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import LinkRightModal from './Modals/link-right.vue';
    import LinkBrandModal from './Modals/link-brand.vue';
    import LinkServiceModal from './Modals/link-service.vue';
    import RightEdit from './user-right-edit.vue';
    export default {
        components: {
            RightEdit,
            LinkRightModal,
            LinkBrandModal,
            LinkServiceModal,
        },
        props: {
            user: {
                type: Object,
                required: true,
            },
            rights: {
                type: Array,
                required: true,
            },
            brands: {
                type: Array,
                required: true,
            },
            services: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                userRights: [],
                userServices: [],
                tableData: [],
                fields: [
                    { key: 'brand', label: 'Marque' },
                    { key: 'service', label: 'Service' },
                    { key: 'right', label: 'Droit' },
                    { key: 'key', label: 'Clé' },
                    { key: 'value', label: 'Valeur' },
                ],
                show: true,
            };
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            userBrands() {
                let brands = this.userRights.map((right) => right.brand);
                let uniqueBrands = [...new Set(brands)];
                return uniqueBrands;
            },
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.$set(this, 'tableData', []);
                this.show = true;
                this.getUserRights(this.user.uid)
                    .then((res) => {
                        this.$set(this, 'userRights', res.data);
                        let promises = [];
                        for (let brand of this.userBrands) {
                            promises.push(this.getUserServices({ userUid: this.user.uid, brand }));
                        }
                        Promise.all(promises)
                            .then((serviceRes) => {
                                this.$set(this, 'userServices', serviceRes.map((service) => service.data).flat());
                                for (let uRight of this.userRights) {
                                    let relatedRight = this.rights.find((right) => right.rightId === uRight.rightId);
                                    if (!relatedRight) continue;
                                    this.tableData.push({
                                        brand: uRight.brand,
                                        service: relatedRight.service,
                                        right: relatedRight.service,
                                        key: relatedRight.key,
                                        value: uRight.value,
                                        rightId: uRight.rightId,
                                        rightInitialDbId: uRight.id,
                                    });
                                }
                            })
                            .catch((e) => {
                                throw e;
                            })
                            .finally(() => {
                                this.show = false;
                            });
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la récupération des données'));
                    });
            },
            ...mapActions({
                getUserRights: 'app/getUserRights',
                getUserServices: 'app/getUserServices',
                updateUserRight: 'app/updateUserRight',
                deleteUserRight: 'app/deleteUserRight',
                actionLinkRight: 'app/linkRight',
                actionLinkBrand: 'app/linkBrand',
                actionLinkService: 'app/linkService',
            }),

            updateRights(value, rightId, rightInitialDbId) {
                this.show = true;
                let data = {
                    uid: this.user.uid,
                    value: value,
                    rightId: rightId,
                    id: rightInitialDbId,
                };
                this.updateUserRight({ userUid: this.user.uid, rightInitialDbId, data })
                    .then((res) => {
                        this.$toast(this.successToast("Les droits de l'utilisateurs ont été mis à jours"));
                        this.init();
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast("Erreur lors de la mise à jour des droits de l'utilisateur"));
                    })
                    .finally(() => {
                        this.show = false;
                    });
            },

            deleteRight(rightId, rightInitialDbId) {
                this.show = true;

                this.deleteUserRight({ userUid: this.user.uid, rightInitialDbId, rightId })
                    .then((res) => {
                        this.$toast(this.successToast("Les droits de l'utilisateurs ont été supprimé"));
                        this.init();
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast("Erreur lors de la suppression des droits de l'utilisateur"));
                    })
                    .finally(() => {
                        this.show = false;
                    });
            },

            openModal(modalRef) {
                this.$refs[modalRef].open();
            },

            linkRight(data) {
                data.uid = this.user.uid;
                this.actionLinkRight({ userUid: data.uid, brand: data.brand, data })
                    .then((res) => {
                        this.$toast(this.successToast('Le nouveau droit a été ajouté au user ' + this.user.email));
                        this.init();
                        this.$refs['link-right-modal'].close();
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast("Erreur lors de l'ajout du nouveau droit"));
                    });
            },

            linkBrand(brand) {
                this.actionLinkBrand({ userUid: this.user.uid, data: { brand } })
                    .then((res) => {
                        this.$toast(this.successToast('La nouvelle marque a été ajouté au user ' + this.user.email));
                        this.init();
                        this.$refs['link-brand-modal'].close();
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast("Erreur lors de l'ajout de la nouvelle marque"));
                    });
            },

            linkService(data) {
                data.uid = this.user.uid;
                this.actionLinkService(data)
                    .then((res) => {
                        this.$toast(this.successToast('Le nouveau service a été ajouté au user ' + this.user.email));
                        this.init();
                        this.$refs['link-service-modal'].close();
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast("Erreur lors de l'ajout du nouveau service"));
                    });
            },
        },
    };
</script>

<style></style>
