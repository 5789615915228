<template>
    <b-card>
        <b-input-group class="mb-1 w-25 d-flex ml-auto">
            <b-input-group-prepend>
                <b-input-group-text>
                    <feather-icon icon="SearchIcon"></feather-icon>
                </b-input-group-text>
            </b-input-group-prepend>
            <b-input v-model="filter"></b-input>
        </b-input-group>
        <b-table ref="table" striped responsive :filter="filter" :items="rights" :fields="fields" :per-page="perPage" :current-page="currentPage" hover @filtered="onTableFiltered">
            <template #cell(created_at)="data">
                {{ data.value | humanizeDate }}
            </template>
            <template #cell(updated_at)="data">
                {{ data.value | humanizeDate }}
            </template>
        </b-table>
        <b-pagination class="mt-3" v-model="currentPage" align="right" :total-rows="rows" :per-page="perPage" first-number></b-pagination>

        <b-btn variant="primary" @click="openModalCreateRight">Créer un droit</b-btn>
        <generic-confirm-modal ref="modal-create-right" title="Création d'un droit" @on-accept="createRight" @on-cancel="closeModalCreateRight">
            <b-overlay :show="creatingRight">
                <b-form @submit.stop.prevent>
                    <b-form-group label="Service" label-for="input-select-service">
                        <b-form-select
                            id="input-select-service"
                            v-model="selectedService"
                            :options="services"
                            value-field="name"
                            text-field="name"
                            :state="ruleSelectedService || !showFormError ? null : false"
                        ></b-form-select>
                        <b-form-invalid-feedback :state="ruleSelectedService || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group label="Clé" label-for="input-right-key">
                        <b-input id="input-right-key" v-model="rightKey" :state="ruleRightKey || !showFormError ? null : false"></b-input>
                        <b-form-invalid-feedback :state="ruleRightKey || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
                    </b-form-group>
                </b-form>
            </b-overlay>
        </generic-confirm-modal>
    </b-card>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        props: {
            rights: {
                type: Array,
                required: true,
            },
            services: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                fields: [
                    { key: 'rightId', label: 'RightId' },
                    { key: 'service', label: 'Service' },
                    { key: 'key', label: 'Clé' },
                    { key: 'created_at', label: 'Date de création' },
                    { key: 'updated_at', label: 'Date de mise à jour' },
                ],
                perPage: 10,
                currentPage: 1,
                filter: '',
                rows: 0,
                creatingRight: false,
                selectedService: '',
                rightKey: '',
                showFormError: false,
            };
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            ruleSelectedService() {
                return this.selectedService.length > 0;
            },
            ruleRightKey() {
                return this.rightKey !== '';
            },
            validForm() {
                return [this.ruleSelectedService, this.ruleRightKey].every((rule) => rule === true);
            },
        },
        watch: {
            rights: {
                immediate: true,
                handler(val) {
                    this.setRows(val);
                },
            },
        },
        methods: {
            setRows(list) {
                this.$set(this, 'rows', list.length);
            },

            onTableFiltered(list) {
                this.setRows(list);
            },
            openModalCreateRight() {
                this.$refs['modal-create-right'].open();
            },
            createRight() {
                if (!this.validForm) return (this.showFormError = true);
                this.creatingRight = true;
                this.$store
                    .dispatch('app/createRight', { serviceName: this.selectedService, data: { key: this.rightKey } })
                    .then((res) => {
                        this.$toast(this.successToast('Le droit à été ajouté!'));
                        this.closeModalCreateRight();
                        this.$emit('on-refresh');
                    })
                    .catch((e) => {
                        this.$toast(this.errorToast("Erreur lors de l'ajout du droit"));
                    })
                    .finally(() => {
                        this.creatingRight = false;
                    });
            },
            closeModalCreateRight() {
                this.showFormError = false;
                (this.rightKey = ''), (this.selectedService = ''), this.$refs['modal-create-right'].close();
            },
        },
    };
</script>

<style></style>
