<template>
    <b-card>
        <b-input-group class="mb-1 w-25 d-flex ml-auto">
            <b-input-group-prepend>
                <b-input-group-text>
                    <feather-icon icon="SearchIcon"></feather-icon>
                </b-input-group-text>
            </b-input-group-prepend>
            <b-input v-model="filter"></b-input>
        </b-input-group>
        <b-table
            ref="table"
            striped
            responsive
            :filter="filter"
            :items="usersForTable"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            hover
            @row-clicked="toggleRow"
            @filtered="onTableFiltered"
        >
            <template #cell(created_at)="data">
                {{ data.value | humanizeDate }}
            </template>
            <template #cell(updated_at)="data">
                {{ data.value | humanizeDate }}
            </template>
            <template #row-details="row">
                <user-edit-right :user="row.item" :rights="rights" :brands="brands" :services="services"></user-edit-right>
            </template>
        </b-table>
        <b-pagination class="mt-3" v-model="currentPage" align="right" :total-rows="rows" :per-page="perPage" first-number></b-pagination>
        <b-btn variant="primary" @click="openModalCreateUser">Créer un utilisateur</b-btn>
        <generic-confirm-modal ref="modal-create-user" title="Création d'un utilisateur" @on-accept="createUser" @on-cancel="closeModalCreateUser">
            <b-overlay :show="creatingUser">
                <b-form @submit.stop.prevent>
                    <b-form-group label="Email" label-for="input-user-email">
                        <b-input id="input-user-email" v-model="userEmail" :state="ruleUserEmail || !showFormError ? null : false"></b-input>
                        <b-form-invalid-feedback :state="ruleUserEmail || !showFormError ? null : false"> Un email valide doit être renseigné </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group label="Password" label-for="input-user-password">
                        <b-input id="input-user-password" v-model="userPassword" type="password" :state="ruleUserPassword || !showFormError ? null : false"></b-input>
                        <b-form-invalid-feedback :state="ruleUserPassword || !showFormError ? null : false"> Un mot de passe de plus de 3 charactères doit être renseigné </b-form-invalid-feedback>
                    </b-form-group>
                </b-form>
            </b-overlay>
        </generic-confirm-modal>
    </b-card>
</template>

<script>
    import UserEditRight from './user-info-table.vue';
    import { mapGetters } from 'vuex';
    export default {
        components: {
            UserEditRight,
        },
        props: {
            users: {
                type: Array,
                required: true,
            },
            rights: {
                type: Array,
                required: true,
            },
            brands: {
                type: Array,
                required: true,
            },
            services: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                fields: [
                    { key: 'uid', label: 'UID' },
                    { key: 'email', label: 'Email' },
                    { key: 'active', label: 'Status' },
                    { key: 'created_at', label: 'Date de création' },
                    { key: 'updated_at', label: 'Date de mise à jour' },
                ],
                perPage: 10,
                currentPage: 1,
                filter: '',
                rows: 0,
                userEmail: '',
                userPassword: '',
                showFormError: false,
                creatingUser: false,
                emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            };
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            ruleUserEmail() {
                let email = this.userEmail;
                if (email === '') return false;
                return email.match(this.emailRegex) !== null;
            },
            ruleUserPassword() {
                let password = this.userPassword;
                return password.length > 3;
            },
            usersForTable() {
                let usersForTable = this.users.map((user) => {
                    return {
                        ...user,
                        _showDetails: false,
                    };
                });
                if (this.filter === '') this.setRows(usersForTable);
                return usersForTable;
            },
            formValid() {
                return [this.ruleUserEmail, this.ruleUserPassword].every((val) => val === true);
            },
        },
        mounted() {},
        methods: {
            toggleRow(row) {
                let user = this.usersForTable.find((user) => user._showDetails === true);
                if (user) user._showDetails = false;
                if (user?.email === row.email) return;
                row._showDetails = !row._showDetails;
            },
            setRows(list) {
                this.$set(this, 'rows', list.length);
            },

            onTableFiltered(list) {
                this.setRows(list);
            },

            openModalCreateUser() {
                this.$refs['modal-create-user'].open();
            },

            createUser() {
                this.creatingUser = true;
                if (!this.formValid) {
                    this.creatingUser = false;
                    return (this.showFormError = true);
                }
                this.$store
                    .dispatch('app/createUser', { email: this.userEmail, password: this.userPassword })
                    .then((res) => {
                        this.$toast(this.successToast("L'utilisateur à été ajouté"));
                        this.$emit('on-refresh');
                    })
                    .catch((e) => {
                        this.$toast(this.errorToast("Erreur lors de l'ajout de l'utilisateur"));
                    })
                    .finally(() => {
                        this.$refs['modal-create-user'].close();
                        this.creatingUser = false;
                    });
            },

            closeModalCreateUser() {
                this.showFormError = false;
                this.userEmail = '';
                this.userPassword = '';
            },
        },
    };
</script>

<style></style>
