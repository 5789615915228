<template>
    <div>
        <b-form @submit.stop.prevent class="d-inline-block">
            <b-input v-model="localValue" class="d-inline-block" :state="validateValue" size="sm" style="width: 100px"></b-input>
            <b-form-invalid-feedback :state="validateValue"> La valeur doit être un nombre > 0 </b-form-invalid-feedback>
        </b-form>
        <b-btn class="ml-1" size="sm" variant="warning" @click="openAlertUpdateRight"> <feather-icon icon="Edit3Icon"></feather-icon></b-btn>
        <b-btn class="ml-1" size="sm" variant="danger" @click="openAlertDeleteRight">
            <feather-icon icon="TrashIcon"></feather-icon>
        </b-btn>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        props: {
            userRight: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                localValue: 0,
            };
        },
        computed: {
            ...mapGetters({
                modalContext: 'app/modalContext',
            }),
            validateValue() {
                return !isNaN(parseInt(this.localValue)) && this.localValue >= 0;
            },
        },
        watch: {
            userRight: {
                handler(val) {
                    this.localValue = val.value;
                },
                immediate: true,
            },
        },
        methods: {
            openAlertUpdateRight() {
                this.$bvModal.msgBoxConfirm("Êtes-vous sûr de vouloir modifier les droits de l'utilisateur ?", this.modalContext()).then((confirmed) => {
                    if (confirmed) {
                        this.$emit('on-update-right', this.localValue, this.userRight.rightId, this.userRight.rightInitialDbId);
                    }
                });
            },

            openAlertDeleteRight() {
                this.$bvModal.msgBoxConfirm("Êtes-vous sûr de vouloir supprimer les droits de l'utilisateur ?", this.modalContext('danger')).then((confirmed) => {
                    if (confirmed) {
                        this.$emit('on-delete-right', this.userRight.rightId, this.userRight.rightInitialDbId);
                    }
                });
            },
        },
    };
</script>

<style></style>
